<!-- @Author: Yu_Bo -->
<template>
    <div class=''>
        <div class="table_wrap">
            <el-table ref="table1" key="table1" :data="list" v-loading="loading" style="width: 100%"
                header-cell-class-name="table-header" cell-class-name="cell-class">
                <template slot="empty">
                    <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
                </template>
                <el-table-column prop="id" label="序号">
                    <!-- <template slot-scope="scope">
                        {{ scope.$index + 1 }}
                    </template> -->
                </el-table-column>
                <el-table-column prop="name" label="漫画作品名称" width="125">
                </el-table-column>
                <el-table-column prop="" label="漫画作品" width="145">
                    <template slot-scope="scope">
                        <div class="draw_box" v-if="scope.row.production_file" @click="previewBtn(scope.row)">
                            <el-image style="width: 100%; height: 100%" :src="scope.row.production_file ? scope.row.production_file[0] : ''" fit="cover"></el-image>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="" label="客户信息" width="145">
                    <template slot-scope="scope">
                        <div class="">
                            <div class="txt_wrap">{{ scope.row.member.nickname }}</div>
                            <div class="txt_wrap">{{ scope.row.member.mobile }}</div>
                            <div v-if="scope.row.member.member_type_text == '个人'" class="isPerson">个人</div>
                            <div v-if="scope.row.member.member_type_text == '企业'" class="isCompony">企业</div>
                            <div v-if="scope.row.member.member_type_text == '代理'" class="isAgency">代理</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="order_source" label="作品来源">
                </el-table-column>
                <el-table-column prop="num" label="消耗">
                </el-table-column>
                <el-table-column prop="balance" label="剩余次数">
                </el-table-column>
                <el-table-column prop="create_time" label="创作时间">
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="60">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" :disabled="scope.row.shelf_status == 2" @click="shelf(scope.row)">
                            {{ scope.row.shelf_status == 2 ? '已下架' : '下架' }}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据

export default {
    components: {
        EmptyData,
    },
    props: {
        list: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
        }
    },
    computed: {},
    watch: {},
    created() {
        // console.log(this.topup)
    },
    mounted() { },
    methods: {
        // 预览
        previewBtn(row) {
            this.$emit('previewBtn', row)
        },
        shelf(row) {
            this.$emit('shelfBtn', row.id)
        }
    },
}
</script>

<style lang='scss' scoped>
@import "./index.scss";
</style>