<template>
    <div class="zc_dialog_box">
        <el-dialog title="查看" :visible.sync="dialogVisible" width="762px" :close-on-click-modal=false>
            <div class="main">
                <div class="main-l">
                    <el-carousel height="260px" :autoplay="false" indicator-position="none" arrow="always"
                        @change="changeImg" v-if="info.production_file && info.production_file.length">
                        <el-carousel-item v-for="item, index in info.production_file" :key="index">
                            <div class="img">
                                <img :src="item" alt="">
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                    <div class="indicator" v-if="info.production_file && info.production_file.length">{{ current }}/{{
                        info.production_file.length }}</div>
                </div>
                <div class="main-r">
                    <div class="main-r-c">
                        <div class="main-t">
                            <!-- <div class="user_box">
                                <div class="avatar">
                                    <img src="" alt="">
                                </div>
                                <div class="name">{{ info.member.nickname }}</div>
                            </div> -->
                            <div class="li">
                                <div class="li-l">漫画比例：</div>
                                <div class="li-r">{{ info.proportion }}</div>
                            </div>
                            <div class="li">
                                <div class="li-l">创建时间：</div>
                                <div class="li-r">{{ info.create_time }}</div>
                            </div>
                            <div class="li">
                                <div class="li-l">参考图：</div>
                                <div class="li-r">
                                    <span class="look" @click="imageBtn">查看</span>
                                    <span class="desc">（相似度{{ info.reference_rate }}%）</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
        <!-- 查看图片 -->
        <image-amplify ref="image"></image-amplify>
    </div>
</template>

<script>
import ImageAmplify from '@/components/swiper_list/image_amplify.vue'

export default {
    components: {
        ImageAmplify
    },
    props: {

    },
    data() {
        return {
            dialogVisible: false,
            current: 1,
            info: {}
        };
    },
    computed: {
    },
    created() {

    },
    methods: {
        open(info) {
            this.info = info
            this.dialogVisible = true
        },
        changeImg(val) {
            this.current = val + 1
        },
        // 查看
        imageBtn() {
            this.$refs.image.openDialog(this.info.reference_pic)
        },
    },
};
</script>

<style scoped lang="scss">
.main {
    width: 100%;
    height: 422px;
    display: flex;

    .main-l {
        width: 422px;
        height: 412px;
        background: #f8f8f8;
        border-radius: 10px;
        padding-top: 76px;
        position: relative;
        margin-right: 20px;

        .img {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 260px;
                height: auto;
            }
        }

        .indicator {
            position: absolute;
            width: 48px;
            height: 20px;
            background: rgba(0, 0, 0, 0.08);
            border-radius: 10px;
            font-size: 12px;
            color: #666666;
            line-height: 20px;
            text-align: center;
            left: 187px;
            bottom: 16px;
        }
    }

    .main-r {
        width: 280px;
        height: 412px;
        background: #f8f8f8;
        border-radius: 10px;
        padding: 14px 0 14px 14px;
        overflow-y: auto;
        .main-r-c {
            .main-t {
                width: 100%;
                // height: 280px;
                font-size: 12px;
                color: #666666;
                line-height: 17px;
                overflow-y: auto;

                .inspiration {
                    color: #333333;
                    padding-top: 6px;
                    width: 253px;
           
                }

                .style {
                    display: flex;
                    margin-top: 14px;

                    .style-tit {
                        width: 68px;
                        line-height: 25px;
                        padding-right: 4px;
                    }

                    .style-list {
                        width: 210px;

                        .style-item {
                            display: inline-block;
                            color: #2e4bf2;
                            line-height: 17px;
                            padding: 4px 12px;
                            background: rgba(46, 75, 242, 0.1);
                            border-radius: 2px;
                            margin-right: 6px;
                            margin-bottom: 6px;
                        }
                    }
                }

                .artist {
                    display: flex;
                    margin-top: 8px;

                    .artist-tit {
                        width: 68px;
                        line-height: 25px;
                        padding-right: 4px;
                    }

                    .artist-item {
                        display: inline-block;
                        color: #ff7432;
                        line-height: 17px;
                        padding: 4px 12px;
                        background: rgba(255, 116, 50, 0.1);
                        border-radius: 2px;
                    }
                }

                .li {
                    display: flex;
                    margin-top: 14px;

                    .li-l {
                        width: 68px;
                        padding-right: 8px;
                    }

                    .li-r {
                        color: #333333;

                        .look {
                            color: #2e4bf2;
                            cursor: pointer;
                        }
                    }
                }
            }

            .btn {
                width: 251px;
                height: 44px;
                background: linear-gradient(90deg, #4875ff 0%, #2b4afe 100%);
                border-radius: 2px;
                font-size: 15px;
                margin-top: 8px;

                i {
                    color: #ffffff;
                    font-size: 16px;
                }
            }
        }

        .btn-box {
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;
        }
    }

    ::v-deep .main-r::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 4px;
        /*高宽分别对应横竖滚动条的尺寸*/
        height: 8px;
    }

    ::v-deep .main-r::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
        background: rgba(0, 0, 0, 0.3);
    }

    ::v-deep .main-r::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        background: rgba(0, 0, 0, 0.1);
    }
}</style>
